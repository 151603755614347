<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="
        `${$customTable.getCustomTableName($constants.MODULES.InventoryOrders)}`
      "
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
      @filter="onFilter"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Create order"
                @click="addItem()"
              >
                <font-awesome-icon icon="file" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: ""
    },
    itemId: {
      type: [Number, String],
      default: ""
    },

    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  name: "OrdersTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Order Date",
            "Shipping Cost",
            "Account Name",
            "Created",
            "Modified",
            "Modified By",
            "Closed"
          ],
          filterable: [
            "ID",
            "Order Date",
            "Shipping Cost",
            "Account Name",
            "Closed",
            "Created",
            "Modified",
            "Modified By"
          ],
          //editableColumns: ["Subject", "Invoice Date", "Assigned To"],
          //dropdownColumns: [{ name: "Assigned To", options: [] }],
          //footerHeadings: false,
          //perPage: 50,
          showActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    if (this.accountId || this.itemId) this.getData();

    this.fetchDropdowns();
  },
  methods: {
    fetchDropdowns() {},
    onFilter() {},
    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      let method = "",
        url = "";

      if (this.accountId) {
        method = "get";
        url = `accounts/${this.accountId}/orders`;
      } else if (this.itemId) {
        method = "get";
        url = `inventory/item/${this.itemId}/orders`;
      } else {
        method = "post";
        url = "inventory/orders";
      }

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response;

          if (response.length === 0) return;
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    addItem: function() {
      this.$router.push({
        name: "Order submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Order submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Order submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Order #${item["ID"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`inventory/order/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },

    onInlineUpdate() {
      /*
      const columnsMapping = [
        { alias: "Subject", dbName: "subject" },
        { alias: "Invoice Date", dbName: "invoice_date" },
        { alias: "Assigned To", dbName: "assigned_to" }
      ];

      let value = e.value;
      let dbName = columnsMapping.find(m => m.alias === e.column).dbName;

      this.updateField(e.id, dbName, value);
      */
    },

    updateField(id, field, value) {
      let self = this;
      let params = {};
      params["id"] = id;
      params[field] = value;

      this.$api
        .put(`inventory/order/${id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${field}: ${response.message}`);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
