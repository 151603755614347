<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab ref="overview-tab" title="Overview" active>
          <form>
            <h5>
              Item details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>
            <hr />
            <b-row>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.item_name.id"
                  :value="data.item_name"
                  :label="controls.item_name.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-select
                  :id="controls.category.id"
                  :value="data.category"
                  :loading="controls.category.loading"
                  :label="controls.category.label"
                  :readonly="controls.category.readonly"
                  :options="controls.category.options"
                  :allow-empty="true"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.serial_number.id"
                  :value="data.serial_number"
                  :label="controls.serial_number.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.amount_remaining.id"
                  :value="data.amount_remaining"
                  :label="controls.amount_remaining.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
            </b-row>
          </form>
          <hr />

          <b-row v-if="this.mode !== this.$constants.FORM_MODE.CREATE">
            <b-col>
              <b-button-group>
                <b-button
                  variant="outline-dark"
                  size="sm"
                  :disabled="dataTable.isInserting"
                  @click="receiveItemModal()"
                  title="Receive item"
                >
                  <font-awesome-icon icon="check" /> Receive item
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table-custom
                ref="dataTable"
                name="ct_inventory_item_history"
                :loading="dataTable.isLoading"
                :data="dataTable.dataSet"
                :options="dataTable.options"
                @inline-update="onInlineUpdate"
                @inline-dropdown-change="onInlineDropdownChange"
              >
                <div slot="custom-actions" slot-scope="props">
                  <div class="btn-group">
                    <button
                      :class="[
                        props.row['Notes']
                          ? 'btn btn-secondary btn-sm'
                          : 'btn btn-outline-secondary btn-sm'
                      ]"
                      @click="toggleDescription(props.row['ID'])"
                    >
                      <font-awesome-icon
                        icon="file"
                        v-if="!props.row['Notes']"
                      />
                      <font-awesome-icon
                        icon="file-alt"
                        v-if="props.row['Notes']"
                      />
                    </button>
                  </div>
                </div>
              </table-custom>
            </b-col>
          </b-row>
          <hr />
        </b-tab>
        <b-tab
          :title="controls.tabs.orders.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <orders-table :item-id="id" @loaded="onOrdersLoad" />
        </b-tab>
      </b-tabs>
      <form-submission-actions
        v-if="action !== 'preview'"
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Inventory Items' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Inventory Item Submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Inventory Item Submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>

    <receive-item-modal
      ref="receiveItemModal"
      @save="onReceiveItemModalSave"
      @close="onReceiveItemModalClose"
    />
  </div>
</template>

<script>
import Vue from "vue";
import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import FormSubmissionActions from "@/components/FormSubmissionActions";
import TableCustom from "@/components/TableCustom";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import ReceiveItemModal from "../ReceiveItemModal.vue";

Vue.use(CKEditor);
import moment from "moment";
import OrdersTable from "@/views/Inventory/OrdersTable";

import { mapState, mapActions } from "vuex";
export default {
  name: "InventoryItemSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FormSubmissionActions,
    TableCustom,
    ReceiveItemModal,
    OrdersTable
  },
  data: function() {
    return {
      itemId: undefined,
      baseApiUrl: "inventory/item",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      mode: undefined,
      editor: {
        mode: InlineEditor,
        data: "",
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },

      controls: {
        tabs: {
          orders: {
            title: "Purchases"
          }
        },
        category: {
          id: "select:category",
          label: "Category",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        item_name: {
          id: "input:item_name",
          label: "Item Name",
          changed: false,
          readonly: false
        },
        serial_number: {
          id: "input:serial_number",
          label: "Serial Number",
          changed: false,
          readonly: false
        },
        amount_remaining: {
          id: "input:amount_remaining",
          label: "Amount Remaining",
          changed: false,
          readonly: true
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        category: {
          id: "",
          label: ""
        },
        item_name: "",
        serial_number: "",
        amount_remaining: ""
      },
      dropdowns: {
        orderNumbers: [],
        storageLocations: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "HID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            "HID",
            "Order Item ID",
            "Order Number",
            "Order Date",
            "Ordered By",
            "Total Price",
            "Total Amount",
            "Delivery Status",

            "Est Arrival Date",
            "Date Received",
            "Lot Number",
            "Storage Location",
            "Total Received",
            "Lot Amount Received",
            "Amount Remaining",
            "Amount Adjusted Loss",
            "Status"
          ],

          editableColumns: [
            "Lot Number",
            "Storage Location",
            "Date Received",
            "Amount Adjusted Loss"
          ],

          dropdownColumns: [
            {
              name: "Order Number",
              options: []
            },
            {
              name: "Storage Location",
              options: []
            }
          ],
          perPage: 50,
          //showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize(_id) {
      this.itemId = _id ? _id : this.id;

      console.log(this.itemId);
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.itemId;

      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.itemId) {
        this.loadData(this.itemId);
      }
    },
    onOrdersLoad(count) {
      if (count > 0) this.controls.tabs.orders.title = `Purchases (${count})`;
    },

    updateActiveOrders() {
      if (!this.itemId) return;

      let self = this;

      self.$api
        .get(`inventory/item/${this.itemId}/orders/active`)
        .then(response => {
          console.log(`inventory/item/${this.itemId}/orders/active`, response);
          self.dropdowns.activeOrders = response;

          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Order Number"
          ).options = response.map(u => ({
            id: u["Order Number"],
            label: "Order " + u["Order Number"] + " on " + u["Order Date"]
          }));
        });
    },

    loadDictionaries() {
      let self = this;

      const categories = () => {
        this.$api
          .get("dictionaries/inventory/item-categories")
          .then(response => {
            self.controls.category.options = response.map(item => {
              return {
                id: item.id,
                label: item.name
              };
            });
          });
      };

      const storages = () => {
        self.$api
          .get(`dictionaries/inventory/storage-location`)
          .then(response => {
            self.dropdowns.storageLocations = response;

            self.dataTable.options.dropdownColumns.find(
              c => c.name === "Storage Location"
            ).options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      };
      Promise.all([categories(), this.updateActiveOrders(), storages()]);
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let url = this.baseApiUrl,
        method = "";

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }

      console.log("save", url, JSON.stringify(this.data));

      this.isLoading = true;
      return this.$api[method](url, this.data)
        .then(response => {
          console.log("response", response);

          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);
    },

    async loadData(id) {
      let self = this;

      this.isLoading = true;

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`);

        this.isLoading = false;

        if (self.$_.isEmpty(response)) return;

        this.data.id = response["ID"];

        this.data.item_name = response["Item Name"];

        this.data.serial_number = response["Serial Number"];
        this.data.amount_remaining = response["Amount Remaining"];

        this.data.category = {
          id: response["item_category"],
          label: response["Category"]
        };

        this.data.created = response["Created"];
        this.data.modified = response["Modified"];

        this.drawDataTable(this.itemId);
      } catch (error) {
        console.log(error);

        this.$form.makeToastError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async drawDataTable() {
      this.dataTable.isLoading = true;

      let url = `inventory/item/${this.itemId}/history`;

      let response = await this.$api.get(url);

      this.rawData = response;

      this.dataTable.isLoading = false;

      this.dataTable.dataSet = this.rawData;
    },
    toggleDescription(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    onDescriptionBlur(row) {
      this.onInlineUpdate({
        id: row["ID"],
        column: "Notes",
        value: row["Notes"]
      });
    },
    onInlineUpdate(e) {
      console.log("onInlineUpdate", e);

      let row = this.dataTable.dataSet.find(i => i["HID"] === e.id);

      /*
      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }
*/
      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put(`inventory/item/${row["ID"]}/history`, row)
          .then(response => {
            this.$form.makeToastInfo(response.message);

            this.drawDataTable();
          })
          .catch(function(error) {
            this.$form.makeToastError(error.message);
            this.drawDataTable();
          });
      }
    },
    onInlineDropdownChange(e) {
      console.log("onInlineDropdownChange", e);

      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Storage Location") {
        row["storage_location_id"] = e.value.id;
      }

      if (e.column === "Order Number") {
        let order = this.dropdowns.activeOrders.find(
          i => i["Order Number"] === e.value.id
        );
        row["item_order_id"] = order["Item Order ID"];
        row["order_id"] = order["Order Number"];
        row["Order Date"] = order["Order Date"];
        row["Ordered By"] = order["Ordered By"];
        row["Total Price"] = order["Total Price"];
        row["Total Amount"] = order["Total Amount"];
        row["Delivery Status"] = order["Delivery Status"];
        row["Est Arrival Date"] = order["Est Arrival Date"];
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    addItemInline() {
      let newItem = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        order_id: "",
        "Order Number": "",
        "Order Date": "",
        "Ordered By": "",
        "Total Price": "",
        "Total Amount": "",
        "Delivery Status": "",
        "Est Arrival Date": "",
        "Date Received": moment
          .utc()

          .format("YYYY-MM-DD"),
        "Lot Number": "",
        "Storage Location": "",
        "Amount Received": "",
        "Amount Remaining": "",
        "Amount Adjusted Loss": "",
        Status: ""
      };

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);

      // this.dataTable.dataSet.push(newItem);

      this.dataTable.isInserting = true;
    },
    receiveItemModal() {
      this.$refs.receiveItemModal.show({ item_id: this.itemId });
    },
    onReceiveItemModalSave(payload) {
      this.$refs.receiveItemModal.hide();
      this.$api
        .post(`inventory/item/${payload.id}/history`, payload)
        .then(response => {
          this.$form.makeToastInfo(response.message);

          this.drawDataTable();
        })
        .catch(function(error) {
          this.$form.makeToastError(error.message);
          this.drawDataTable();
        });
    },
    onReceiveItemModalClose() {
      this.$refs.receiveItemModal.hide();
    },
    async saveNewRecordCallback(newRecord) {
      let self = this;

      if (!newRecord["Order Number"]) {
        self.$form.makeToastError("Please select an order");
        return;
      }
      if (!newRecord["Date Received"]) {
        self.$form.makeToastError("Please enter date received");
        return;
      }
      if (!newRecord["Lot Number"]) {
        self.$form.makeToastError("Please enter lot number");
        return;
      }

      if (!newRecord["Amount Received"]) {
        self.$form.makeToastError("Please enter amount received");
        return;
      }

      if (!newRecord["Storage Location"]) {
        self.$form.makeToastError("Please select storage location");
        return;
      }

      let data = {};

      data["item_order_id"] = newRecord["item_order_id"];
      data["order_id"] = newRecord["order_id"];
      data["storage_location_id"] = newRecord["storage_location_id"];
      data["lot_number"] = newRecord["Lot Number"];
      data["date_received"] = newRecord["Date Received"];
      data["amount_received"] = newRecord["Amount Received"];
      data["amount_adjusted_loss"] = 0;
      data["status"] = "Full";

      console.log(data);
      self.dataTable.isInserting = false;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.updateActiveOrders();

        this.$api
          .post(`inventory/item/${this.itemId}/history`, data)
          .then(response => {
            self.$form.makeToastInfo(response.message);
            self.drawDataTable();
            self.updateActiveOrders();
          })
          .catch(function(error) {
            self.$form.makeToastError(error.message);

            self.drawDataTable();
            self.updateActiveOrders();
          });
      } else {
        return true;
      }
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    }
  },
  watch: {}
};
</script>

<style scoped></style>
