var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$constants.MODULES.InventoryOrders)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "view-item": _vm.viewItem,
      "edit-item": _vm.editItem,
      "delete-item": _vm.deleteItem,
      "filter": _vm.onFilter,
      "inline-update": _vm.onInlineUpdate
    }
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_vm.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Create order"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "file"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }