var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "overview-tab",
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Item details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.item_name.id,
      "value": _vm.data.item_name,
      "label": _vm.controls.item_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "loading": _vm.controls.category.loading,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.serial_number.id,
      "value": _vm.data.serial_number,
      "label": _vm.controls.serial_number.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_remaining.id,
      "value": _vm.data.amount_remaining,
      "label": _vm.controls.amount_remaining.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1)], 1), _c('hr'), this.mode !== this.$constants.FORM_MODE.CREATE ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "disabled": _vm.dataTable.isInserting,
      "title": "Receive item"
    },
    on: {
      "click": function click($event) {
        return _vm.receiveItemModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "check"
    }
  }), _vm._v(" Receive item ")], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "ct_inventory_item_history",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "inline-update": _vm.onInlineUpdate,
      "inline-dropdown-change": _vm.onInlineDropdownChange
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          class: [props.row['Notes'] ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleDescription(props.row['ID']);
            }
          }
        }, [!props.row['Notes'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file"
          }
        }) : _vm._e(), props.row['Notes'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file-alt"
          }
        }) : _vm._e()], 1)])]);
      }
    }])
  })], 1)], 1), _c('hr')], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.orders.title
    }
  }, [_c('orders-table', {
    attrs: {
      "item-id": _vm.id
    },
    on: {
      "loaded": _vm.onOrdersLoad
    }
  })], 1) : _vm._e()], 1), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Inventory Items'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Inventory Item Submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Inventory Item Submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1), _c('receive-item-modal', {
    ref: "receiveItemModal",
    on: {
      "save": _vm.onReceiveItemModalSave,
      "close": _vm.onReceiveItemModalClose
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }